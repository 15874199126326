:root {
    --error-color: rgb(216, 39, 54);
    --main-color: rgb(27, 88, 193);
  }
.formGroup{
    margin-bottom: 1rem;
    display: flex;
    text-align: left;
    font-size: 1rem;
}
.formGroup input, .formGroup  select, .formGroup  textarea{
    padding: 0.5rem;
    width: 300px;
    max-width: 100%;
    background-color: transparent;
    border: 2px solid #ccc;
    box-shadow: none;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    transition: all 0.2s ease-in;
    letter-spacing: 2px;
    color: rgb(50, 50, 50);
    margin-bottom: 5px;
}
.formGroup textarea{
    width: 600px;
    max-width: 100%;
    min-height: 300px;
}
.formGroup input:focus, .formGroup textarea:focus, .formGroup select:focus{
    background: rgba(243, 253, 255, 0.844);
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.formGroup label{
    padding: 0.5rem;
    display: inline-block;
    width: 150px;
}

.formGroup.error input{
    border: 2px solid var(--error-color);
}
.formGroup.error input:focus{
    background: rgba(255, 247, 247, 0.844);
    box-shadow: 0 0 0 0.25rem rgba(249, 188, 188, 0.886) !important;
    border: 2px solid var(--error-color);
    outline: rgb(255, 79, 79);
}
.error .errorMessage{
    color: var(--error-color);
    display: block;
}
.btn{
    width: 100%;
    display: block;
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    color: white;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    width: 500px;
    max-width: 100%;
}
.btn:hover{
    border-color: rgb(44, 103, 232);
    background-color: rgb(44, 103, 232);
    box-shadow: 0 0 25px rgba(205, 205, 205, 0.886) !important;
}

.btn:focus{
    border-color: rgb(16, 52, 130);
    background-color: rgb(16, 52, 130);
}

@media screen and (max-width: 769px){
    .formGroup label, .formGroup input, .formGroup textarea, .formGroup select{
        width: 100%;
        display: block;
    }
    .formGroup{
        flex-direction: column;
        margin: 0px;
    }
    .textAreaGroup{
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }
    .textAreaGroup label{
        width: auto;
    }
    .textAreaGroup p{
        float: left;
        margin-right: 1rem;
    }
}

.floatRight{
    float: right;
}